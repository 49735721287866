import { FunctionComponent } from 'react'

import { generateRandomBetween } from '@utils/helpers'

import { IAdditionalPanelsProps } from './AdditionalPanels.types'

// Used to not repeat panels while exists unused panels
const panelsQueueCache: number[] = []
let panelsQueue: number[] = []

// Used to ensure the next panel will be different than the last one
let lastPanel: number

/*
 * Returns a random additional panel
 * Displayed after a interval of cards in jobs list
 */

const AdditionalPanels: FunctionComponent<IAdditionalPanelsProps> = ({
  position = 0,
  interval = 0,
  panels = [],
  count = 0
}) => {
  const maxPanels = Math.floor((count - 1) / interval)
  const index = Math.floor(position / interval) - 1
  const hasPanels = panels.length > 0
  const notFirstPosition = position > 0
  const panelsQueueHaveAll = panelsQueue.length === panels.length
  const isInterval = position % interval === 0
  const cacheIsFull = panelsQueueCache.length === maxPanels

  if (hasPanels && notFirstPosition && isInterval) {
    if (cacheIsFull) {
      return panelsQueueCache[index] ? panels[panelsQueueCache[index]] : null
    }

    if (panelsQueueHaveAll) {
      panelsQueue = []
    }

    const exclude = [...panelsQueue, lastPanel]
    const panel = generateRandomBetween(0, panels.length, exclude)

    if (!isNaN(panel)) {
      panelsQueue.push(panel)
      lastPanel = panel
      panelsQueueCache.push(panel)
      return panels[panel]
    }
  }

  return null
}

export default AdditionalPanels
